const AVERAGE_WINRATE = 65;
const WINRATE_DEVIATION = 6;

const MATCH_REWARDS = [-15, 30];
const REWARD_DEVIATION = 1;

const BATCH_UPDATE_MATCH_COUNT = 10;
const BATCH_UPDATE_MATCH_DEVIATION = 5;

const createWinLossFromTrophy = trophy => {
    const winrate = getDeviation(AVERAGE_WINRATE, WINRATE_DEVIATION);
    const gainInOneMatch = winrate * MATCH_REWARDS[1] / 100 + (100 - winrate) * MATCH_REWARDS[0] / 100;
    const multiplier = limitDecimals((trophy / gainInOneMatch), 3);
    return {
        victories: Math.round(winrate * multiplier / 100),
        defeats: Math.round((100 - winrate) * multiplier / 100)
    }

}

const createTrophyFromWinLoss = profile => {
    let trophy = 0;
    for (let i = 0; i < profile.victories; i++) {
        trophy += getDeviation(MATCH_REWARDS[1], REWARD_DEVIATION);
    }
    for (let i = 0; i < profile.defeats; i++) {
        trophy += getDeviation(MATCH_REWARDS[0], REWARD_DEVIATION);
    }
    return trophy
}

const getUpdatedMatchCountForUser = user => {
    const matchCount = getDeviation(BATCH_UPDATE_MATCH_COUNT, BATCH_UPDATE_MATCH_DEVIATION);
    const winLoseDiff = getDeviation(1, 3);
    const victories = Math.floor((matchCount + winLoseDiff) / 2);
    const defeats = matchCount - victories;
    const trophyUpdate = createTrophyFromWinLoss({ victories, defeats });

    user.profile.victories += victories;
    user.profile.defeats += defeats;
    user.trophy += trophyUpdate;
    // console.log(user)
    // console.log(matchCount, winLoseDiff, victories, defeats);
    user.profile.maximum_trophies = Math.max(user.trophy, user.profile.maximum_trophies);
    user.profile.winrate = Math.round( user.profile.victories * 100 / (user.profile.victories + user.profile.defeats));

    return user
}

module.exports = {
    createTrophyFromWinLoss,
    createWinLossFromTrophy,
    getUpdatedMatchCountForUser
}

const limitDecimals = (val, decimals) => {
    return Math.round(val * Math.pow(10, decimals)) / Math.pow(10, decimals);
}

const getDeviation = (val, deviation) =>  {
    const sign = Math.sign(Math.random() - 0.5);
    const devValue = Math.round(Math.random() * deviation);
    return  val + sign * devValue;
};