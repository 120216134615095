<template>
  <div class="main-container">
    <h1 class="main-heading">{{$t('MOCK_USERS')}}</h1>
    <div class="content-container">
      <div class="table">
        <div class="table-row header-row alternate">
          <div class="cell">{{ $t('NAME') }}</div>
          <div class="cell">{{ $t('TROPHY') }}</div>
          <div class="cell">
            <a href="/mock-users/add" class="success">{{ $t('ADD') }}</a>
            <button class="success" @click="applyTrophyChanges">{{ $t('TROPHY_PROGRESS_MOCK_USERS') }}</button>
          </div>
        </div>
        <div class="table-row" v-for="(mockUser, index) in mockUsers" v-bind:key="mockUser.id" :class="index % 2 === 0 ? '' : 'alternate'">
          <div class="cell">{{ mockUser.name }}</div>
          <div class="cell">{{ mockUser.id }}</div>
          <div class="cell">
            <a :href="'/mock-users/add?id=' + mockUser.id" class="info">{{ $t('EDIT') }}</a>
            <button @click="() => deleteMockUser(mockUser.id)" class="error">{{ $t('REMOVE') }}</button>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { watch, ref, computed, onBeforeUnmount, onMounted } from "vue";
import { useStore } from "vuex";

import { getUpdatedMatchCountForUser } from "@/util/mockUserCalculations";
// import mockUser from "@/schemas/mockUser";

export default {
  name: "MockUsers",
  setup() {
    const store = useStore();
    const mockUsersArr = ref([]);
    watch(() => store.getters['mockUsers/getMockUsers'], value => {
      mockUsersArr.value = [ ...value ]
    }, { immediate: true })

    onMounted(() => store.dispatch("loader/loadingStatus", false));
    onBeforeUnmount(() => store.dispatch("loader/loadingStatus", true));

    const mockUsers = computed(() => store.getters["mockUsers/getMockUsers"]);

    const deleteMockUser = id => {
      store.dispatch('mockUsers/removeMockUser', id)
    }

    const applyTrophyChanges = () => {
      mockUsersArr.value.forEach(mockUser => {
        let newUser = JSON.parse(JSON.stringify(mockUser));
        newUser = getUpdatedMatchCountForUser(newUser);
        // console.log(newUser)
        store.dispatch("mockUsers/editMockUser", { userId: newUser.id, userBody: newUser })
      })
    }

    return {
      mockUsersArr,
      mockUsers,
      deleteMockUser,
      applyTrophyChanges
    }
  }
}
</script>

<style scoped>
.main-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 30px;
}

.content-container {
  width: 100%;
}
.table {

}
.table-row {
  padding: 10px 0;
  width: 100%;
  display: grid;
  grid-template-columns: 5fr 5fr 2fr;
  text-align: start;
}

.table-row.alternate {
  background-color: lightgray;
}
.table-row.header-row {
  font-weight: 600;
  font-size: 1.125rem;
}
.cell {
  display: grid;
  padding: 0 10px;
  align-items: center;
}


button, a {
  text-decoration: none;
  color: black;
  font-weight: 400;
  font-size: 0.9rem;
  align-self: stretch;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.07);
  border-radius: 10px;
  border: 1px solid gray;
  padding: 5px 20px;
  margin: 5px 10px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

button.error, a.error {
  background-color: rgba(255, 0, 0, 0.15);
}

button.success, a.success {
  background-color: rgba(0, 255, 0, 0.15);
}

button.info, a.info {
  background-color: rgba(0, 0, 255, 0.15);
}
</style>